import { gql } from '@apollo/client';

export const UPLOAD_REPORT_AS_ADMIN = gql`
  mutation uploadReportAsAdmin(
    $orderId: String!
    $reportFileUrl: String!
    $testId: String!
  ) {
    uploadReportAsAdmin(
      data: {
        orderId: $orderId
        testId: $testId
        reportFileUrl: $reportFileUrl
      }
    ) {
      order {
        id
        orderId
        orderStatus
        orderTransitStatus
      }
      isOrderExist
    }
  }
`;

export const REJECT_REPORT_AS_ADMIN = gql`
  mutation rejectReportAsAdmin(
    $orderId: String!
    $testId: String!
    $rejectionReason: String!
  ) {
    rejectReportAsAdmin(
      data: {
        orderId: $orderId
        testId: $testId
        rejectionReason: $rejectionReason
      }
    ) {
      order {
        id
        orderStatus
        orderTransitStatus
      }
      isOrderExist
    }
  }
`;

export const REJECT_WHOLE_REPORT_AS_ADMIN = gql`
  mutation rejectWholeReportAsAdmin(
    $orderId: String!
    $rejectionReason: String!
  ) {
    rejectWholeReportAsAdmin(
      orderId: $orderId
      rejectionReason: $rejectionReason
    ) {
      order {
        id
        orderStatus
        orderTransitStatus
      }
      isOrderExist
    }
  }
`;

export const APPROVE_REPORT_AS_ADMIN = gql`
  mutation approveReportAsAdmin($orderId: String!, $testId: String!) {
    approveReportAsAdmin(data: { orderId: $orderId, testId: $testId }) {
      order {
        id
        orderStatus
        orderTransitStatus
      }
      isOrderExist
    }
  }
`;

export const APPROVE_WHOLE_REPORT_AS_ADMIN = gql`
  mutation approveWholeReportAsAdmin($orderId: String!) {
    approveWholeReportAsAdmin(orderId: $orderId) {
      order {
        id
        orderStatus
        orderTransitStatus
      }
      isOrderExist
    }
  }
`;

export const MARK_REPORT_AS_DELIVERED = gql`
  mutation markReportAsDeliveredByAdmin($orderId: String!) {
    markReportAsDeliveredByAdmin(data: { orderId: $orderId }) {
      order {
        id
        orderStatus
        orderTransitStatus
        __typename
      }
      isOrderExist
    }
  }
`;

export const REPLACE_SAMPLE_COLLECTOR = gql`
  mutation replaceSampleCollector(
    $orderId: String!
    $sampleCollectorId: String!
    $data: String!
    $time: String!
  ) {
    replaceSampleCollector(
      data: {
        orderId: $orderId
        sampleCollectorId: $sampleCollectorId
        date: $data
        time: $time
      }
    )
  }
`;

export const CANCEL_ORDER = gql`
  mutation cancelOrderAsAdmin($orderId: String!) {
    cancelOrderAsAdmin(orderId: $orderId) {
      order {
        id
      }
    }
  }
`;

export const CANCEL_ORDER_FORCEFULLY = gql`
  mutation cancelOrderWithoutRefund($orderId: String!) {
    cancelOrderWithoutRefund(orderId: $orderId) {
      order {
        id
      }
    }
  }
`;

export const SAMPLES_SUBMIT_TO = gql`
  mutation submitSampleTo($orderId: String!, $labId: String!) {
    submitSampleTo(orderId: $orderId, labId: $labId)
  }
`;

export const RESCHEDULE_SAMPLE_COLLECTION = gql`
  mutation rescheduleSampleCollection(
    $orderId: String!
    $slotId: String!
    $selectedDate: String!
  ) {
    rescheduleSampleCollection(
      data: { orderId: $orderId, slotId: $slotId, selectedDate: $selectedDate }
    ) {
      order {
        id
      }
    }
  }
`;

export const UPLOAD_WHOLE_REPORT_AS_ADMIN = gql`
  mutation uploadWholeReportAsAdmin(
    $orderId: String!
    $reportFileUrl: String!
  ) {
    uploadWholeReportAsAdmin(
      data: { orderId: $orderId, reportFileUrl: $reportFileUrl }
    ) {
      order {
        id
      }
      isOrderExist
    }
  }
`;

export const CHANGE_ORDER_ADDRESS = gql`
  mutation updateOrderAddress($orderId: String!, $addressId: String!) {
    updateOrderAddress(orderId: $orderId, addressId: $addressId) {
      cart {
        id
      }
    }
  }
`;

export const UPDATE_PATIENT_IN_ORDER = gql`
  mutation updatePatientInOrder($orderId: String!, $patientId: String!) {
    updatePatientInOrder(orderId: $orderId, patientId: $patientId) {
      cart {
        id
      }
    }
  }
`;

export const FORCED_REPLACE_SAMPLE_COLLECTOR = gql`
  mutation replaceSampleCollectorForce(
    $orderId: String!
    $sampleCollectorId: String!
    $data: String!
    $time: String!
  ) {
    replaceSampleCollectorForce(
      data: {
        orderId: $orderId
        sampleCollectorId: $sampleCollectorId
        date: $data
        time: $time
      }
    )
  }
`;
