import { gql } from '@apollo/client';

// CREATE CATEGORY
export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
    $testsId: [String]!
  ) {
    createCategory(
      data: { name: $name, thumbnail: $thumbnail, testsId: $testsId }
    ) {
      category {
        id
        name
        isFeatured
        order
        serialNumber
        thumbnail
        isDisabled
        linkedTests {
          total
          tests {
            id
            title
          }
        }
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
    $testsId: [String]!
    $categoryId: String!
  ) {
    updateCategory(
      data: { name: $name, thumbnail: $thumbnail, testsId: $testsId }
      id: $categoryId
    ) {
      category {
        id
        name
        isFeatured
        order
        serialNumber
        thumbnail
        isDisabled
        linkedTests {
          total
          tests {
            id
            title
          }
        }
      }
    }
  }
`;

// CREATE SAMPLE
export const CREATE_SAMPLE = gql`
  mutation createSample(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
  ) {
    createSample(data: { name: $name, thumbnail: $thumbnail }) {
      sample {
        id
        name
        serialNumber
        thumbnail
      }
    }
  }
`;

// CREATE EQUIPMENT
export const CREATE_EQUIPMENT = gql`
  mutation createEquipment(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $availableQuantity: Int!
  ) {
    createEquipment(
      data: { name: $name, availableQuantity: $availableQuantity }
    ) {
      equipment {
        id
        name
        serialNumber
        minimumQuantity
        stock {
          id
          availableQuantity
        }
      }
    }
  }
`;
// CREATE ADMIN TESTIMONIAL
export const CREATE_ADMIN_TESTIMONIAL = gql`
  mutation createTestimonialAsAdmin(
    $user: String!
    $testimonial: String!
    $ratings: Int!
  ) {
    createTestimonialAsAdmin(
      data: { user: $user, testimonial: $testimonial, ratings: $ratings }
    ) {
      testimonial {
        id
        user
        testimonial
        ratings
        order
        serialNumber
        isFeatured
        createdAt
      }
    }
  }
`;

export const TOGGLE_FEATURE_CATEGORY = gql`
  mutation toggleFeatureCategory($categoryId: String!) {
    toggleFeatureCategory(categoryId: $categoryId) {
      result
    }
  }
`;

export const TOGGLE_FEATURE_TEST = gql`
  mutation toggleFeatureTest($testId: String!) {
    toggleFeatureTest(testId: $testId) {
      result
    }
  }
`;

// TOGGLE ENABLE CATEGORY
export const TOGGLE_ENABLE_CATEGORY = gql`
  mutation toggleEnabledCategory($categoryId: String!) {
    toggleEnabledCategory(categoryId: $categoryId) {
      result
    }
  }
`;

// TOGGLE TESTIMONIAL
export const TOGGLE_FEATURE_TESTIMONIAL = gql`
  mutation toggleFeatureTestimonial($testimonialId: String!) {
    toggleFeatureTestimonial(testimonialId: $testimonialId) {
      result
    }
  }
`;

// UPDATE EQUIPMENT
export const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $availableQuantity: Int!
    $id: String!
  ) {
    updateEquipment(
      data: { name: $name, availableQuantity: $availableQuantity }
      id: $id
    ) {
      equipment {
        id
        name
        serialNumber
        minimumQuantity
        stock {
          id
          availableQuantity
        }
      }
    }
  }
`;

// UPDATE SAMPLE
export const UPDATE_SAMPLE = gql`
  mutation updateSample(
    $name: name_String_NotNull_minLength_2_maxLength_255!
    $thumbnail: thumbnail_String_NotNull_maxLength_5000_format_uri!
    $id: String!
  ) {
    updateSample(data: { name: $name, thumbnail: $thumbnail }, id: $id) {
      sample {
        id
        name
        serialNumber
        thumbnail
      }
    }
  }
`;

export const CREATE_TEST = gql`
  mutation createTest($data: CreateTestInput!) {
    createTest(data: $data) {
      test {
        id
        thumbnailUrl
        title
        serialNumber
        description
        isSandbox
        parentTestId
        instruction
        isPackage
        productionCostWRTLab {
          productionCost
          lab {
            id
            name
            displayName
            address
            countryCode
            mobileNumber
          }
          isSelected
        }
      }
    }
  }
`;

export const UPDATE_TEST = gql`
  mutation updateTest($data: CreateTestInput!, $testId: String!) {
    updateTest(data: $data, testId: $testId) {
      test {
        id
        thumbnailUrl
        title
        serialNumber
        description
        isSandbox
        parentTestId
        instruction
        isPackage
        productionCostWRTLab {
          productionCost
          lab {
            id
            name
            displayName
            address
            countryCode
            mobileNumber
          }
          isSelected
        }
      }
    }
  }
`;

export const SYNC_TO_LIVE = gql`
  mutation syncSandboxToLive {
    syncSandboxToLive {
      isSync
    }
  }
`;

export const SYNC_TEST_TO_LIVE = gql`
  mutation syncSandboxTestToLive($testId: String!) {
    syncSandboxTestToLive(testId: $testId) {
      isSync
    }
  }
`;

export const ARCHIVE_TEST = gql`
  mutation archiveTest($id: String!) {
    archiveTest(id: $id)
  }
`;

export const UNARCHIVE_TEST = gql`
  mutation unarchiveTest($id: String!) {
    unarchiveTest(id: $id)
  }
`;

export const ADD_BULK_TESTS = gql`
  mutation createTestsInBulk($data: CreateTestBulkInput) {
    createTestsInBulk(data: $data)
  }
`;

export const TOGGLE_FEATURE_ADDON = gql`
  mutation toggleFeatureAddonsTest($addOnsTestId: String!) {
    toggleFeatureAddonsTest(addOnsTestId: $addOnsTestId) {
      result
    }
  }
`;

export const ADD_ADDONS = gql`
  mutation createAddOnsTest($testIds: [String!]!) {
    createAddOnsTest(testIds: $testIds) {
      tests {
        id
        title
        serialNumber
      }
    }
  }
`;

export const REMOVE_ADDON = gql`
  mutation removeAddOnsTest($addOnsTestId: String!) {
    removeAddOnsTest(addOnsTestId: $addOnsTestId)
  }
`;
