import { Button, Space, Typography, message } from 'antd';
import { Header } from 'antd/es/layout/layout';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as Back } from '../../assets/icons/back.svg';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { getPayVia, getTag } from '../../common/components/common-components';
import {
  DEBOUNCE_TIME,
  GLOBAL_PAGINATION_LIMIT,
  ROUTES,
  defaultDateFormat
} from '../../common/constants';
import {
  getCustomerOrders,
  placeOrderAgainAsAdmin
} from './customers-repository';

function CustomerOrderHistory() {
  const limit = GLOBAL_PAGINATION_LIMIT;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const format = defaultDateFormat;
  // STATES
  const [viewOrder, setViewOrder] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const fetchViewHistoryOrders = async ({ currentSkip, currentQuery }) => {
    try {
      const id = searchParams.get('customerId');
      const response = await getCustomerOrders(id, {
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setViewOrder(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  useEffect(() => {
    fetchViewHistoryOrders({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (value, record) => {
        return (
          <Typography
            className="clickable-title"
            onClick={() => {
              const url = `${ROUTES.REPORT_DETAIL}?orderId=${record.id}`;
              navigate(url);
            }}
          >
            {value}
          </Typography>
        );
      }
    },

    {
      title: 'Booked on',
      dataIndex: 'scheduledAt',
      key: 'scheduledAt',
      render: (value) => {
        if (!value) return '';
        return moment(value).format(format);
      }
    },
    {
      title: 'Scheduled on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) => {
        if (!value) return '';
        return moment(value).format(format);
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'subTotal',
      key: 'subTotal',
      render: (value, record) => {
        return `₹ ${(value + (record.coinsApplied || 0)).toFixed(2)}`;
      }
    },
    {
      title: 'Payment mode',
      dataIndex: 'email',
      key: 'email',
      render: (value, record) => {
        return (
          <div>
            {getPayVia(value)} {getTag(record.paymentStatus)}
          </div>
        );
      }
    },
    {
      title: 'Assigned to',
      dataIndex: 'sampleCollectionPartner',
      key: 'sampleCollectionPartner',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (value) => {
        return (
          <Button
            style={{
              border: 'none',
              borderRadius: '8px',
              backgroundColor: '#0d1756',
              height: '40px',
              color: 'white'
            }}
            onClick={async () => {
              const key = 'updatable';
              message.open({
                key,
                type: 'loading',
                content: 'Adding tests to cart'
              });
              const res = await placeOrderAgainAsAdmin(value);
              if (res.success === false) {
                message.open({
                  key,
                  type: 'error',
                  content: res.error
                });
                return;
              }
              message.open({
                key,
                type: 'success',
                content: 'Cart created'
              });
              navigate(-1);
            }}
          >
            Book Again
          </Button>
        );
      }
    }
  ];

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Button
              className="back-btn"
              type="default"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Back />
            </Button>
            <Space>
              <Typography.Text className="main-header-title">
                Order's History
              </Typography.Text>
            </Space>
          </Space>
          <SearchComponent
            name="Customers"
            debounceTime={DEBOUNCE_TIME}
            getData={(value) => {
              setQuery(value);
              setLoadingData(true);
              fetchViewHistoryOrders({
                currentQuery: value,
                currentSkip: 0
              });
            }}
          />
        </div>
      </Header>
      <div className="main-content customers-content">
        <div className="customers-table">
          {loading ? (
            <LoaderComponent />
          ) : (
            <CommonTable
              columns={columns}
              rowKey={(record) => record.id}
              loadingData={loadingData}
              paginationConfig={paginationProp}
              data={viewOrder}
              onChange={(onChange) => {
                // eslint-disable-next-line no-console
                setLoadingData(true);
                fetchViewHistoryOrders({
                  currentQuery: query,
                  currentSkip: (onChange.current - 1) * limit
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomerOrderHistory;
