/* eslint-disable no-console */
import client from '../../apollo';
import {
  ADD_ADDRESS_AS_ADMIN,
  ADD_CUSTOMER,
  ADD_PATIENT,
  ADD_TEST_TO_CUSTOMER_CART,
  APPLY_COUPON_AS_ADMIN,
  BOOK_AGAIN_AS_ADMIN,
  CLEAR_CART,
  DELETE_ADDRESS,
  DELETE_PATIENT,
  PLACE_ORDER_AS_ADMIN,
  TOGGLE_DELIVER_REPORT,
  TOGGLE_ENABLE_CUSTOMER,
  TOGGLE_USE_COINS,
  UPDATE_ADDRESS_AS_ADMIN,
  UPDATE_CUSTOMER,
  UPDATE_PATIENT_AS_ADMIN
} from './graphql/mutation';
import {
  GET_ALL_CUSTOMERS,
  GET_COUPONS_AS_ADMIN,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_CART_AS_ADMIN,
  GET_CUSTOMER_ORDER_HISTORY,
  GET_LABS_BY_CART,
  GET_WHATSAPP_MESSAGES_BY_ID
} from './graphql/query';

export const getAllCustomers = async (filters) => {
  const { skip, limit, query, hideDeletedUser } = filters;
  try {
    const response = await client.query({
      query: GET_ALL_CUSTOMERS,
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          skip,
          limit,
          query,
          hideDeletedUser
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getAllCustomers;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};
export const getCustomerById = async (customerId) => {
  try {
    const response = await client.query({
      query: GET_CUSTOMER_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    // eslint-disable-next-line no-console
    const customer = response?.data?.getCustomerByIdAsAdmin?.customer;
    const patients = response?.data?.getPatientsAsAdmin?.patients || [];
    const addresses =
      response?.data?.getCustomerAddressesAsAdmin?.addresses || [];
    return { ...customer, patients, addresses };
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const addCustomerAsAdmin = async (data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_CUSTOMER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return response?.data?.addCustomerAsAdmin?.customer;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updateCustomerAsAdmin = async (data) => {
  try {
    await client.mutate({
      mutation: UPDATE_CUSTOMER,
      fetchPolicy: 'network-only',
      variables: data
    });
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const toggleCustomerEnable = async (customerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_ENABLE_CUSTOMER,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });

    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const updatePatientAsAdmin = async (patientId, data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_PATIENT_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        patientId,
        data
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const createPatientAsAdmin = async (customerId, data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_PATIENT,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        data
      }
    });
    return response?.data.createPatientAsAdmin;
  } catch (error) {
    return false;
  }
};

export const removePatientAsAdmin = async (patientId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_PATIENT,
      fetchPolicy: 'network-only',
      variables: {
        patientId
      }
    });
    return response?.data;
  } catch (error) {
    return false;
  }
};

export const updateCustomerAddressAsAdmin = async (customerId, data) => {
  try {
    const response = await client.mutate({
      mutation: UPDATE_ADDRESS_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        data
      }
    });
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const createCustomerAddressAsAdmin = async (customerId, data) => {
  try {
    const response = await client.mutate({
      mutation: ADD_ADDRESS_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        data
      }
    });
    return response?.data?.createCustomerAddressAsAdmin;
  } catch (error) {
    return false;
  }
};

export const removeAddressAsAdmin = async (addressId) => {
  try {
    const response = await client.mutate({
      mutation: DELETE_ADDRESS,
      fetchPolicy: 'network-only',
      variables: {
        addressId
      }
    });
    // eslint-disable-next-line no-console
    return response?.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getCustomerOrders = async (customerId, filters) => {
  try {
    const { skip, limit } = filters;
    const response = await client.mutate({
      mutation: GET_CUSTOMER_ORDER_HISTORY,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        filters: {
          skip,
          limit
        }
      }
    });
    return response?.data?.getCustomerOrders;
  } catch (error) {
    // eslint-disable-next-line no-console
    return false;
  }
};

export const getCustomerCartAsAdmin = async (customerId) => {
  try {
    const response = await client.query({
      query: GET_CUSTOMER_CART_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getCustomerCartAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const addTestToCustomerCart = async (data) => {
  const { customerId, tests } = data;
  console.log(customerId);
  try {
    const response = await client.query({
      query: ADD_TEST_TO_CUSTOMER_CART,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        tests
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.addTestToCustomerCart;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const clearCustomerCart = async (cartId) => {
  try {
    const response = await client.mutate({
      mutation: CLEAR_CART,
      fetchPolicy: 'network-only',
      variables: {
        cartId
      }
    });
    return response?.data;
  } catch (error) {
    return [];
  }
};

export const placeOrderAsAdmin = async (data) => {
  try {
    const response = await client.mutate({
      mutation: PLACE_ORDER_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        data
      }
    });
    return response?.data.placeOrderAsAdmin;
  } catch (error) {
    return false;
  }
};

export const getOfferCouponsAsAdmin = async (customerId) => {
  try {
    const response = await client.query({
      query: GET_COUPONS_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    return response?.data?.getOfferCouponsAsAdmin;
  } catch (error) {
    return [];
  }
};

export const applyOfferAsAdmin = async (offerId, customerId) => {
  try {
    const response = await client.mutate({
      mutation: APPLY_COUPON_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        offerId,
        customerId
      }
    });
    return response?.data?.applyOfferAsAdmin;
  } catch (error) {
    return [];
  }
};

export const toggleUseCoinsAsAdmin = async (customerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_USE_COINS,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    return response?.data?.toggleUseCoinsAsAdmin;
  } catch (error) {
    return [];
  }
};

export const toggleDeliveryAtHomeAsAdmin = async (customerId) => {
  try {
    const response = await client.mutate({
      mutation: TOGGLE_DELIVER_REPORT,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    return response?.data?.toggleDeliveryAtHomeAsAdmin;
  } catch (error) {
    return [];
  }
};

export const placeOrderAgainAsAdmin = async (orderId) => {
  try {
    const response = await client.mutate({
      mutation: BOOK_AGAIN_AS_ADMIN,
      fetchPolicy: 'network-only',
      variables: {
        orderId
      }
    });
    return response?.data.placeOrderAgainAsAdmin;
  } catch (error) {
    return [];
  }
};

export const getLabsFromTestsAsAdmin = async (customerId) => {
  try {
    const response = await client.query({
      query: GET_LABS_BY_CART,
      fetchPolicy: 'network-only',
      variables: {
        customerId
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getLabsFromTestsAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const getCustomerWhatasappHistory = async (customerId, filters) => {
  const { skip, limit } = filters;
  try {
    const response = await client.query({
      query: GET_WHATSAPP_MESSAGES_BY_ID,
      fetchPolicy: 'network-only',
      variables: {
        customerId,
        filters: {
          skip,
          limit
        }
      }
    });
    // eslint-disable-next-line no-console
    return response?.data?.getWhatsappMessageHistoryByCustomerIdAsAdmin;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
};

export const test = async () => {};
