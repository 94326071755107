import { gql } from '@apollo/client';

export const CREATE_OFFERS = gql`
  mutation createOffer(
    $title: title_String_NotNull_minLength_2_maxLength_255!
    $kind: DiscountKind!
    $discount: Float!
    $tillValid: Date!
    $startDate: Date!
    $couponCode: couponCode_String_NotNull_minLength_2_maxLength_20!
    $minimumOrderOf: Float!
    $description: String!
    $maximumOrder: Int
    $isFirstUser: Boolean
  ) {
    createOffer(
      data: {
        title: $title
        kind: $kind
        discount: $discount
        tillValid: $tillValid
        startDate: $startDate
        couponCode: $couponCode
        minimumOrderOf: $minimumOrderOf
        description: $description
        maximumOrder: $maximumOrder
        isFirstUser: $isFirstUser
      }
    ) {
      offer {
        id
        title
        kind
        discount
        startDate
        tillValid
        couponCode
        minimumOrderOf
        order
        serialNumber
        description
        maximumOrder
        isFirstUser
      }
    }
  }
`;

export const TOGGLE_FEATURE_OFFER = gql`
  mutation toggleFeatureOffer($offerId: String!) {
    toggleFeatureOffer(offerId: $offerId) {
      result
    }
  }
`;

// TOGGLE ENABLE OFFER
export const TOGGLE_ENABLE_OFFER = gql`
  mutation toggleEnabledOffer($offerId: String!) {
    toggleEnabledOffer(offerId: $offerId) {
      result
    }
  }
`;

// UPDATE OFFER
export const UPDATE_OFFER = gql`
  mutation updateOffer(
    $title: title_String_NotNull_minLength_2_maxLength_255!
    $tillValid: Date!
    $startDate: Date!
    $offerId: String!
    $description: String!
    $minimumOrderOf: Float!
    $maximumOrder: Int
    $isFirstUser: Boolean
  ) {
    updateOffer(
      data: {
        title: $title
        startDate: $startDate
        tillValid: $tillValid
        description: $description
        minimumOrderOf: $minimumOrderOf
        maximumOrder: $maximumOrder
        isFirstUser: $isFirstUser
      }
      offerId: $offerId
    ) {
      offer {
        id
        title
        kind
        discount
        startDate
        tillValid
        couponCode
        minimumOrderOf
        order
        serialNumber
        isDisabled
        description
        maximumOrder
        isAutoApply
        isFirstUser
      }
    }
  }
`;

export const UPDATE_LINKED_TESTS = gql`
  mutation updateLinkedTest($data: UpdateLinkedTestInput!) {
    updateLinkedTest(data: $data)
  }
`;

export const TOGGLE_AUTO_APPLY = gql`
  mutation toggleAutoApply($offerId: String!) {
    toggleAutoApply(offerId: $offerId)
  }
`;

export const TOGGLE_HIDDEN_OFFER = gql`
  mutation toggleHiddenOffer($offerId: String!) {
    toggleHiddenOffer(offerId: $offerId)
  }
`;

export const SAMPLE = gql`
  query {
    checkGraphQLStatus
  }
`;
