/* eslint-disable no-console */
import { Button, Divider, Modal, Select, Typography, message } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import CommonTable from '../../../common/components/CommonTable';
import LoaderComponent from '../../../common/components/LoaderComponent';
// eslint-disable-next-line import/no-named-as-default
import OrderDetails from './OrderDetails';

import {
  getPayVia,
  getSourceTag,
  getTag,
  normalizeEnums
} from '../../../common/components/common-components';
import {
  GLOBAL_PAGINATION_LIMIT,
  ROUTES,
  defaultDateFormat
} from '../../../common/constants';
import { getLabs } from '../../labs/labs-repository';
import {
  getActiveOrdersAsAdmin,
  getOrdersByIdAsAdmin,
  submitSampleTo
} from '../reports-repository';

const PrintOrderDetails = React.forwardRef(({ orderDetails }, ref) => {
  return (
    <div ref={ref}>
      {orderDetails.map((order, index) => (
        <div key={order.id}>
          <OrderDetails key={order.id} order={order} />
          {index !== orderDetails.length - 1 && (
            <hr style={{ pageBreakAfter: 'always' }} />
          )}
        </div>
      ))}
    </div>
  );
});

function ActiveReports({ query, startDateVal, endDateVal }) {
  const navigate = useNavigate();
  const limit = GLOBAL_PAGINATION_LIMIT;
  const format = defaultDateFormat;
  const { pageNo } = useParams();
  const currentPage = parseInt(pageNo, 10) || 1;
  const componentRef = useRef([]);

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeReports, setActiveReports] = useState([]);
  const [paginationProp, setPaginationProp] = useState();
  const [labs, setLabs] = useState([]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [detailsModal, setDetailsModal] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);

  // FETCHING ACTIVE REPORTS
  const fetchActiveReports = async ({ currentSkip, sDate, eDate }) => {
    setLoadingData(true);
    try {
      //
      const response = await getActiveOrdersAsAdmin({
        skip: currentSkip,
        limit: limit,
        query: query,
        startDate: sDate,
        endDate: eDate
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setActiveReports(response.orders.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };

  // LABS FETCHING
  const fetchLabs = async () => {
    try {
      //
      const response = await getLabs({
        skip: 0,
        limit: 10000,
        query: ''
      });
      setLabs(response.labs.slice());
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    fetchActiveReports({
      currentSkip: (currentPage - 1) * limit,
      sDate: startDateVal,
      eDate: endDateVal
    });
    fetchLabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, currentPage, startDateVal, endDateVal]);

  const handlePageChange = (page) => {
    // Use the navigate function to update the URL
    navigate(`/reports/active/${page}`);
  };

  const selectRow = (record) => {
    const url = `${ROUTES.REPORT_DETAIL}?orderId=${record.id}`;
    navigate(url);
  };

  const handleSelect = async (value, ID) => {
    const { orderId } = ID;
    const key = 'updatable';
    message.open({
      key,
      type: 'loading',
      content: 'Processing'
    });

    await submitSampleTo(orderId, value);
    fetchActiveReports({
      currentSkip: 0
    });
    message.open({
      key,
      type: 'success',
      content: 'Done'
    });
  };

  const handleRowSelect = (selectedRowKeys) => {
    setSelectedRowIds(selectedRowKeys);
  };

  const handleGetSelectedIds = async () => {
    setBulkLoading(true);
    try {
      const response = await getOrdersByIdAsAdmin(selectedRowIds);
      setOrderDetails(response.orders);
      setBulkLoading(false);
      setDetailsModal(true);
    } catch (error) {
      setBulkLoading(false);
      //
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: handleRowSelect
  };

  const handleCancelDetails = () => {
    setDetailsModal(false);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true
  });

  const columns = [
    {
      title: 'Order#',
      dataIndex: 'orderId',
      key: '1',
      render: (value, record) => {
        const { source } = record;
        return (
          <div>
            <Typography
              className="clickable-title"
              onClick={() => {
                selectRow(record);
              }}
            >
              {value}
            </Typography>
            {getSourceTag(source)}
          </div>
        );
      }
    },
    {
      title: 'Patient',
      dataIndex: 'patient',
      key: '2',
      render: (value) => {
        return value.name;
      }
    },
    {
      title: 'Booked on',
      dataIndex: 'scheduledAt',
      key: '3',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format(format);
      }
    },

    {
      title: 'Assigned to',
      dataIndex: 'sampleCollectionPartner',
      key: '4',
      render: (value) => {
        return value?.name;
      }
    },
    {
      title: 'Scheduled at',
      dataIndex: 'scheduledAt',
      key: '5',
      render: (value) => {
        if (!value) return '';
        return dayjs(value).format('hh:mm a');
      }
    },
    {
      title: 'Total amount',
      dataIndex: 'subTotal',
      key: '6',
      render: (value) => {
        return `₹ ${value}`;
      }
    },
    {
      title: 'Payment mode',
      dataIndex: 'paymentVia',
      key: '7',
      render: (value, record) => {
        return (
          <div>
            {getPayVia(value)} {getTag(record.paymentStatus)}
          </div>
        );
      }
    },
    {
      title: 'Booked Status',
      dataIndex: 'orderStatus',
      key: '8',
      align: 'right',
      render: (value) => {
        return normalizeEnums(value);
      }
    },
    {
      title: 'Order Transit Status',
      dataIndex: 'orderTransitStatus',
      key: '9',
      align: 'right',
      render: (value) => {
        return normalizeEnums(value);
      }
    },

    {
      title: 'Order OTP',
      dataIndex: 'otpForTest',
      key: '10'
    },

    {
      title: 'Sample Submitted To',
      dataIndex: 'sampleSubmitTo',
      key: '11',
      render: (value, record) => {
        return (
          <Select
            className="sample-selector"
            onChange={handleSelect}
            placeholder="Select lab"
            value={value?.displayName}
            options={labs.map((lab) => {
              return {
                value: lab.id,
                label: lab.displayName,
                orderId: record.id
              };
            })}
          />
        );
      }
    }
  ];

  return (
    <div className="main-content test-categories-content">
      <Modal
        title="Order Details"
        open={detailsModal}
        onCancel={handleCancelDetails}
        footer={null}
        centered
        className="details-modal"
      >
        <div className="bulk-details-modal">
          <div ref={componentRef}>
            <PrintOrderDetails orderDetails={orderDetails} />
          </div>
        </div>
        <Divider />
        <div className="print">
          <Button
            onClick={() => {
              setDetailsModal(false);
              setOrderDetails([]);
              handlePrint();
            }}
            className="create-btn print-btn"
          >
            PRINT
          </Button>
        </div>
      </Modal>
      {selectedRowIds && selectedRowIds.length < 1 ? null : (
        <Button
          loading={bulkLoading}
          className="bulk-details-btn"
          onClick={handleGetSelectedIds}
        >
          Get Order Details
        </Button>
      )}
      {loading ? (
        <LoaderComponent />
      ) : (
        <CommonTable
          columns={columns}
          rowKey={(record) => record.id}
          loadingData={loadingData}
          rowSelection={rowSelection}
          paginationConfig={paginationProp}
          data={activeReports}
          onChange={(onChange) => {
            setSelectedRowIds([]);
            // eslint-disable-next-line no-console
            setLoadingData(true);
            fetchActiveReports({
              // currentQuery: query,
              currentSkip: (onChange.current - 1) * limit
            });
            handlePageChange(onChange.current);
          }}
        />
      )}
    </div>
  );
}

export default ActiveReports;
