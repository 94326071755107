/* eslint-disable no-console */
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message
} from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../common/components/CommonTable';
import LoaderComponent from '../../common/components/LoaderComponent';
import SearchComponent from '../../common/components/SearchDebounce';
import { DEBOUNCE_TIME, GLOBAL_PAGINATION_LIMIT } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import {
  createPincode,
  getAllPincodes,
  toggleEnablePinCode
} from './pincodes-repository';

function Pincodes() {
  const { required, number } = formValidatorRules;
  const [form] = Form.useForm();
  const limit = GLOBAL_PAGINATION_LIMIT;

  // STATES
  const [loadingData, setLoadingData] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pinCodes, setPinCodes] = useState([]);
  const [query, setQuery] = useState('');
  const [paginationProp, setPaginationProp] = useState();
  const [isSavingData, setIsSavingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentGlobalSkip, setCurrentGlobalSkip] = useState(0);

  const fetchAllCodes = async ({ currentSkip, currentQuery }) => {
    try {
      //
      setCurrentGlobalSkip(currentSkip);
      const response = await getAllPincodes({
        skip: currentSkip,
        limit: limit,
        query: currentQuery
      });
      const newPaginationProp = {
        total: response.total,
        current: currentSkip / limit + 1,
        defaultPageSize: limit
      };
      setPaginationProp(newPaginationProp);
      setPinCodes(response.pinCodes.slice());
    } catch (error) {
      //
    }
    setLoading(false);
    setLoadingData(false);
  };
  useEffect(() => {
    fetchAllCodes({
      currentQuery: '',
      currentSkip: 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MODAL
  const handleCancel = () => {
    form?.resetFields();
    setIsModalOpen(false);
  };

  // TOGGLE FEATURE
  const toggleFeatured = (index) => {
    pinCodes[index] = {
      ...pinCodes[index],
      isDisabled: !pinCodes[index].isDisabled
    };
    toggleEnablePinCode(pinCodes[index].id);
    setPinCodes(pinCodes.slice());
  };

  // COLUMNS
  const columns = [
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area'
    },
    {
      title: 'Code ',
      dataIndex: 'code',
      key: 'code'
    },
    {
      title: 'Availability',
      dataIndex: 'isDisable',
      key: 'kind',
      render: (value, record, index) => {
        return (
          <Checkbox
            defaultChecked={value}
            onClick={() => toggleFeatured(index)}
          />
        );
      }
    }
  ];
  // MODAL

  // CREATE FORM
  const createForm = async (formValue) => {
    setIsSavingData(true);
    const { area, pincode } = formValue;
    const payload = {
      code: pincode,
      area
    };
    const response = await createPincode(payload);
    if (response) {
      const allData = [...pinCodes, response];
      setPinCodes(allData);
      setIsSavingData(false);
      handleCancel();
    } else {
      message.error('Pincode already exist');
      setIsSavingData(false);
      handleCancel();
    }
  };

  return (
    <div className="content-wrapper">
      <Header className="main-header">
        <div className="header-spacer">
          <Space>
            <Typography.Text className="main-header-title">
              Pin Codes
            </Typography.Text>
            <SearchComponent
              name="Pin codes"
              debounceTime={DEBOUNCE_TIME}
              getData={(value) => {
                setQuery(value);
                setLoadingData(true);
                fetchAllCodes({
                  currentQuery: value,
                  currentSkip: 0
                });
              }}
            />
          </Space>
          <Button onClick={() => setIsModalOpen(true)} className="create-btn">
            + Create
          </Button>

          {/* CREATE MODAL */}
          <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            className="offer-modal"
            title="Add Pincode"
            centered
          >
            <Divider />
            <div className="form-contents">
              <Form
                form={form}
                onFinish={createForm}
                className="equipment-form"
                layout="vertical"
              >
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[required]}
                      name="area"
                      className="input-title"
                      label="Area Name"
                    >
                      <Input type="text" placeholder="Area name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="upper-contents">
                  <Col>
                    <Form.Item
                      rules={[required, number]}
                      name="pincode"
                      label="Pincode"
                    >
                      <Input type="text" placeholder="Pincode" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <div className="timely-btn-space ">
                  <Button loading={isSavingData} htmlType="submit">
                    Add
                  </Button>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </Header>
      <div className="main-content test-categories-content">
        {loading ? (
          <LoaderComponent />
        ) : (
          <CommonTable
            columns={columns}
            rowKey={(record) => record.id}
            loadingData={loadingData}
            paginationConfig={paginationProp}
            data={pinCodes}
            onChange={(onChange) => {
              // eslint-disable-next-line no-console
              setLoadingData(true);
              fetchAllCodes({
                currentQuery: query,
                currentSkip: (onChange.current - 1) * limit
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
export default Pincodes;
